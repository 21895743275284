export const Routes = {
    dashboard: {
        route: "/",
        wordKey: "Dashboard",
    },
    balance: {
        route: "/balance",
        wordKey: "Balance",
    },
    conciliacionesBind: {
        route: "/conciliacionesBind",
        wordKey: "Conciliación BIND",
    },
    conciliacionesGIRE: {
        route: "/conciliacionesGIRE",
        wordKey: "Conciliación GIRE",
    },
    liquidacionesPrisma: {
        route: "/liquidaciones-prisma",
        wordKey: "Liquidaciones Prisma",
    },
    feriados: {
        route: "/feriados",
        wordKey: "Feriados",
    },
    seriesDeFeriados: {
        route: "/seriesDeFeriados",
        wordKey: "Series de feriados",
    },
};

export const Movimientos = {
    promosMarketing: {
        route: '/promos-marketing',
        wordKey: "Promos Marketing",
    } 
};

export const Tarjetas = {
    tarjetasTransacciones: {
        route: "/tarjetasTransacciones",
        wordKey: "Transacciones de pago con tarjeta",
    },
    tarjetasAcreditacionMerchant: {
        route: "/tarjetasAcreditacionMerchant",
        wordKey: "Acreditacion al merchant de pagos con tarjeta",
    },
    tarjetasConciliacionGW: {
        route: "/tarjetasConciliacionGW",
        wordKey: "Conciliacion con GW",
    },
    tarjetasEmisores: {
        route: "/tarjetasEmisores",
        wordKey: "Emisores de tarjeta",
    },
    tarjetasMarcas: {
        route: "/tarjetasMarcas",
        wordKey: "Marcas de tarjeta",
    },
    tarjetasPlasticos: {
        route: "/tarjetasPlasticos",
        wordKey: "Plasticos de tarjeta",
    },
    tarjetasCoeficientes: {
        route: "/tarjetasCoeficientes",
        wordKey: "Coeficientes de tarjeta",
    }
};

export const Limites = {
    limites: {
        route: '/limites',
        wordKey: "Límites de condiciones AFIP",
    },
    control: {
        route: '/control',
        wordKey: 'Control de límites'
    }
};