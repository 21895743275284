import React from "react";
import { Fade } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { ModalsStyles } from './Modals.styles';
// forms
import FeriadosForm from '../form/Feriados/FeriadosForm';
import SerieDeFeriadosForm from '../form/SerieDeFeriados/SerieDeFeriadosForm';
import EmisoresForm from "../form/TarjetasEmisores/EmisoresForm";
import PlasticosForm from "../form/TarjetasPlasticos/PlasticosForm";
import MarcasForm from "../form/TarjetasMarcas/MarcasForm";
import CoeficientesForm from "../form/TarjetasCoeficientes/CoeficientesForm";
import LimitesForm from "../form/Limites/LimitesForm";
import ControlLimitesForm from "../form/ControlLimites/ControlLimitesForm";
//modal
import DetalleModal from "../../../pages/balance/DetalleModal";
import ResumenModal from "../../../pages/balance/ResumenModal";
import ComentarioModal from "../../../pages/conciliaciones/ComentarioModal";
import DetalleMovimientoModal from "../../../pages/conciliaciones/DetalleMovimientoModal";
import GireComentarioModal from "../../../pages/conciliacionGIRE/ComentarioModal";
import ConfirmarAcreditacionModal from "../../../pages/tarjetasAcreditaciones/ConfirmarAcreditacionModal";
import DetalleAcreditacionModal from "../../../pages/tarjetasAcreditaciones/DetalleModal";
import ConfirmarTransferenciaModal from "../../../pages/promosMarketing/ConfirmarTransferenciaModal";
import ConfirmarAcreditacionBindModal from "../../../pages/conciliaciones/ConfirmarAcreditacionBindModal";
import ConfirmarReversoModal from "../../../pages/conciliaciones/ConfirmarReversoModal";
// recoil 
import { useRecoilValue } from 'recoil'
import { formIsOpen, routeInfo } from '../../../recoilState/GlobalState';

export default function TransitionModal(props) {
    const classes = ModalsStyles();
    const isOpen = useRecoilValue(formIsOpen);
    const { wordKey: route } = useRecoilValue(routeInfo);

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                open={isOpen}
            >
                <Fade in={isOpen} className={classes.modal}>
                    <div className={classes.modal}>
                        <div className={classes.paper}>
                            {route === 'Balance' ?
                                props.procede === 'Detalle' ?
                                    <DetalleModal props={props} /> : <ResumenModal props={props} /> : null}
                            {route === "Conciliación BIND" ?
                                props.procede === 'detalle' ?
                                    <DetalleMovimientoModal props={props} /> :
                                    props.procede === 'acreditar' ?
                                        <ConfirmarAcreditacionBindModal props={props} /> :
                                        props.procede === 'reversos' ?
                                            <ConfirmarReversoModal props={props} /> :
                                            <ComentarioModal props={props} /> : null}
                            {route === 'Conciliación GIRE' ? <GireComentarioModal props={props} /> : null}
                            {route === 'Feriados' && <FeriadosForm props={props} />}
                            {route === 'Series de feriados' && <SerieDeFeriadosForm props={props} />}
                            {route === 'Acreditacion al merchant de pagos con tarjeta' ?
                                props.procede === 'confirmar' ? <ConfirmarAcreditacionModal props={props} />
                                    : <DetalleAcreditacionModal props={props} />
                                : null}
                            {route === "Emisores de tarjeta" ? <EmisoresForm props={props} /> : null}
                            {route === "Plasticos de tarjeta" ? <PlasticosForm props={props} /> : null}
                            {route === "Marcas de tarjeta" ? <MarcasForm props={props} /> : null}
                            {route === "Coeficientes de tarjeta" ? <CoeficientesForm props={props} /> : null}
                            {route === 'Promos Marketing' ? <ConfirmarTransferenciaModal props={props} /> : null}
                            {route === 'Límites de condiciones AFIP' ? <LimitesForm props={props} /> : null}
                            {route === 'Control de límites' ? <ControlLimitesForm props={props} /> : null}
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
