import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, List, ListItem, ListItemIcon } from '@material-ui/core';
// assets
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import IconTables from "../../assets/icons/icon-tables.svg";
import ArrowDown from "../../assets/icons/arrow-down.svg";
// styles
import { ListDrawerStyles } from './ListDrawer.styles';
// const
import { Movimientos, Routes, Tarjetas, Limites } from "../../consts/Routes-App";
// Recoil
import { useSetRecoilState } from "recoil";
import { routeInfo } from "../../recoilState/GlobalState";

export default function ListDrawer({ setOpen }) {
    const classes = ListDrawerStyles();
    const setRoute = useSetRecoilState(routeInfo);

    const [tarjetasOpen, setTarjetasOpen] = useState(false);
    const [movimientosOpen, setMovimientosOpen] = useState(false);
    const [limitesOpen, setLimitesOpen] = useState(false);

    const handleClick = (route) => {
        setRoute(route);
        setOpen(false);
    }

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            
            {/* Limites */}
            <ListItem button className={classes.itemList} onClick={() => handleClick("/facturacion")}>
                <ListItemIcon>
                    <img className={classes.iconsDrawer} src={IconTables} alt="Tables" />
                </ListItemIcon>
                <span className={classes.textColor}>Facturacion</span>
            </ListItem>
        </List>
    );
}
