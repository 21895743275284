import HeaderButton from "../components/utils/button/HeaderButton";
import Info from "../assets/icons/información.svg";
import { fallbackURL } from '../consts/FallbackURL';

export const logout = () => {
    localStorage.clear();
    window.location.assign(fallbackURL);
};

export const formatNumber = (value) => {
    if (Number.isInteger(value)) {
        const result = new Intl.NumberFormat('es-ES').format(value) + ",00";
        if (result.length === 7) return `${result.substring(0, 1)}.${result.substring(1)}`
        return result
    }
    if (/\.\d$/.test(value.toString())) {
        const result = new Intl.NumberFormat('es-ES').format(value) + "0";
        if (result.length === 7) return `${result.substring(0, 1)}.${result.substring(1)}`
        return result
    }

    const result = new Intl.NumberFormat('es-ES').format(value);
    if (result.length === 7) return `${result.substring(0, 1)}.${result.substring(1)}`;
    return result
};

export const handleResponse = (response, message, functions, toArray) => {
    const { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList } = functions

    if (response) {
        if (response.status.code === 1) {
            setSnackbarInfo({
                message,
                severity: 'success',
                open: true
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
                setIsOpen(false)
                toArray ? setSelected([]) : setSelected({ status: false, row: {} });
            }, 3000);
            updateList();
        }
        else {
            setSnackbarInfo({
                message: response.status.errmsg,
                severity: response.status.action === 'W' ? 'warning' : 'error',
                open: true
            });
            setInRequest(false);
            setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
            }, 3000);
        }
    }
};

export const getGridProps = (columns, rows, row, setter, classes) => {
    return {
        localeText: {
            columnMenuLabel: "Menu",
            columnMenuShowColumns: "Mostrar columnas",
            columnMenuFilter: "Filtrar",
            columnMenuHideColumn: "Esconder",
            columnMenuUnsort: "Desordenar",
            columnMenuSortAsc: "Ordenar por ASC",
            columnMenuSortDesc: "Ordenar por DESC",
        },
        columns,
        rows,
        onRowClick: (data) => validateRowSelected(data, row, setter),
        className: classes.root,
        autoPageSize: true,
        hideFooterSelectedRowCount: true,
        disableMultipleSelection: true,
        pagination: true,
        showCellRightBorder: false,
        rowHeight: 36,
        disableColumnMenu: true
    }
}

export const validateRowSelected = (newSelection, row, setSelected) => {
    if (row && row.id === newSelection.row.id) {
        newSelection.element.classList.remove('Mui-selected')
        return setSelected({ status: false, row: {} });
    }
    const newSelected = { status: true, row: newSelection.row }
    newSelection.element.classList.add('Mui-selected')
    return setSelected(newSelected)
};

export const generateButtons = (conditional, wordkey, handler, icons, updateList) => {
    return conditional ?
        wordkey === 'Plasticos de tarjeta' ?
            <HeaderButton
                text={`Agregar ${wordkey}`}
                icon={icons[0]}
                handleClick={handler}
                data={{ action: 'edit', procede: 'Editone' }}
            />
            :
            <>

            </>
        :
        <>
            <HeaderButton
                text={`Agregar ${wordkey}`}
                icon={icons[2]}
                handleClick={handler}
                data={{ action: 'add', procede: 'Addone' }}
            />
            <HeaderButton
                text={"Actualizar pagina"}
                updateList={updateList}
                icon={icons[3]}
            />
        </>
};

export const transformDate = (str) => `${str.substring(8)}/${str.substring(5, 7)}/${str.substring(0, 4)}`

export const generateColumns = (titles, route) => {
    return titles.reduce((acc, title) => {
        title === 'Saldo anterior' || /\d{4}-\d{2}-\d{2}/.test(title)
        return [...acc,
        {
            field: title,
            headerName: title,
            headerAlign: route === '/control' && /Débito|Crédito|Saldo|Monto|Cantidad|IVA|Fee|Ganancias|II.BB/.test(title) ? 'center' : 'left',
            renderHeader: () => {
                if (route === '/control' && /Monto|Cantidad/.test(title)) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                            <strong style={{ lineHeight: 1, marginBottom: '4px' }}>{title}</strong>
                            <div style={{ display: 'flex', lineHeight: 1, width: '100%', textAlign: 'right' }}>
                                <span style={{ width: '49%', paddingRight: '8px' }}>Valor</span>
                                <span style={{ width: '2%' }}>|</span>
                                <span style={{ width: '49%', paddingLeft: '8px' }}>Limite</span>
                            </div>
                        </div>
                    )
                };
                return <strong>{title}</strong>
            },
            renderCell: (params) => {
                const getColor = () => {
                    const excedeLimitesPropios = params.row.diaExcedePropio === 'Y' || params.row.mesExcedePropio === 'Y';
                    if (excedeLimitesPropios) return '#D44141';

                    const superaMontoDiario = params.row.diaMonto > params.row.diaLimiteMonto;
                    const superaCantidadDiaria = params.row.diaCantidad > params.row.diaLimiteCantidad;
                    const superaMontoMensual = params.row.mesMonto > params.row.mesLimiteMonto;
                    const superaCantidadMensual = params.row.mesCantidad > params.row.mesLimiteCantidad;

                    if (superaMontoDiario || superaCantidadMensual || superaMontoMensual || superaCantidadDiaria) return "#000";
                    return "#63D1A2";
                };

                if (route === '/balance' || route === '/limites' || route === '/tarjetasAcreditacionMerchant') {
                    const style = {
                        width: '100%',
                        textAlign: /Débito|Crédito|Saldo|Monto|Cantidad/.test(title) ? 'right' : 'left'
                    }
                    return <p style={style}>{params.value}</p>
                };

                if (route === '/control' && /Monto|Cantidad/.test(title)) {
                    const style = {
                        width: '100%',
                        textAlign: 'right',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: getColor()
                    }
                    const limite = title === 'Monto diario' ? params.row.diaLimiteMonto : title === 'Cantidad diaria' ? params.row.diaLimiteCantidad : title === 'Monto mensual' ? params.row.mesLimiteMonto : params.row.mesLimiteCantidad;

                    return <p style={style}>
                        <span style={{ width: '49%', paddingRight: '8px' }}>{params.value}</span>
                        <span style={{ width: '2%' }}>|</span>
                        <span style={{ width: '49%', paddingLeft: '8px' }}>{formatNumber(limite)}</span>
                    </p>
                }
                if (route === '/control') {
                    const style = {
                        width: '100%',
                        textAlign: /IVA|Fee|Ganancias|II.BB/.test(title) ? 'right' : 'left',
                        color: getColor()
                    }
                    return <p style={style}>{params.value}</p>
                }
            },
            valueGetter: (params) => {
                if (params.value === null || params.value === undefined) return '';
                if (title === 'Fecha' || title === 'Valido desde') return transformDate(params.value);
                if (title === 'Referencia' || title === 'ID Coelsa' || title === 'Comprobante' || title === 'CUIT' || title === 'Monto diario' || title === 'Cantidad diaria' || title === 'Monto mensual' || title === 'Cantidad mensual') return params.value;
                if (/^\d(.*\d)?$/.test(params.value)) return formatNumber(params.value);
                return params.value;
            },
            sortComparator: (v1, v2, param1, param2) => {
                if (typeof v1 === 'number') return param1.value - param2.value;
                if (/\d{4}-\d{2}-\d{2}/.test(v1) && /\d{4}-\d{2}-\d{2}/.test(v2)) return parseInt(v1.replace(/-/g, '')) - parseInt(v2.replace(/-/g, ''));

                let first = "";
                for (let i = 0; i < v1.length; i++) {
                    first += v1[i].charCodeAt(0).toString(2) + " ";
                }
                let second = "";
                for (let i = 0; i < v2.length; i++) {
                    second += v2[i].charCodeAt(0).toString(2) + " ";
                }
                return parseInt(first, 2) - parseInt(second, 2)
            },
            flex: /Fecha/.test(title) || (/Condición AFIP|CUIT|Nombre/.test(title) && route === '/control') ? 0 : 1,
            width: /Fecha/.test(title) ? 110 : route === '/control' ? /Condición AFIP|CUIT/.test(title) ? 150 : title === 'Nombre' ? 200 : 0 : 0
        }]
    }, [])
};

export const generateCardColumns = (titles) => {
    return titles.reduce((acc, title) => {
        return [...acc,
        {
            field: title,
            headerName: title,
            renderCell: (params) => {
                if (title === 'Monto' || title === 'Monto de Venta') return <p style={{ width: '100%', textAlign: 'right' }}>{params.value}</p>
                if (title === 'Coeficientes' && params.row["Crédito / Débito"] === 'Crédito') {
                    return <p onClick={() => window.location.assign(`/tarjetasCoeficientes/${params.row.id}`)}>Ver</p>
                }
            },
            valueGetter: (params) => {
                if (!params.value) return
                if (/Vigente/.test(title) && params.value) return transformDate(params.value);
                if (/Valor/.test(title) && params.value) return params.value.toFixed(5);
                if (/Monto/.test(title) && params.value) return formatNumber(params.value)
                return params.value
            },
            flex: 1
        }]
    }, [])
};

export const generateConciliationColumns = (titles, width, setOpen, setProcede, setRowInfo, setMovimiento) => {
    return titles.reduce((acc, title) => {
        return [...acc,
        {
            field: title,
            headerName: title,
            renderCell: (params) => {
                const row = params.row;
                const style = { textAlign: /Monto/.test(title) ? 'right' : 'left', width: "100%" };

                if (row.estado === "P") style.color = "#D44141";
                if (row.estado === "C") style.color = "#63D1A2";
                if (row.estado === "O") style.color = "#A7A7A7";
                if (row.estado === "R") style.color = "#FCC020";

                if (/Destino/.test(title) && params.value) return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={style}>{params.value}</p>
                    <img src={Info} alt="Info icon" style={{ height: '20px', width: '20px', marginLeft: '8px' }} onClick={() => {
                        setRowInfo(row)
                        setMovimiento(title === 'Hooli CUIT Destino' ? 'H' : 'B')
                        setProcede('detalle')
                        setOpen(true)
                    }} />
                </div>

                return <p style={style}>{params.value}</p>
            },
            valueGetter: (params) => {
                if (!params.value) return
                if (/Fecha/.test(title)) return transformDate(params.value)
                if (title.startsWith('Monto') && /\d$/.test(params.value)) return formatNumber(params.value)
                return params.value
            },
            sortComparator: (v1, v2, param1, param2) => {
                if (!param1.value || !param2.value) return param1.value - param2.value
                if (typeof v1 === 'number') return param1.value - param2.value;
                if (/\d{4}-\d{2}-\d{2}/.test(v1) && /\d{4}-\d{2}-\d{2}/.test(v2)) return parseInt(v1.replace(/-/g, '')) - parseInt(v2.replace(/-/g, ''))
                let first = "";
                for (let i = 0; i < v1.length; i++) {
                    first += v1[i].charCodeAt(0).toString(2) + " ";
                }
                let second = "";
                for (let i = 0; i < v2.length; i++) {
                    second += v2[i].charCodeAt(0).toString(2) + " ";
                }
                if (v1 === 'Cash in') return (parseInt(first, 2) + 1) - parseInt(second, 2);
                return parseInt(first, 2) - parseInt(second, 2)
            },
            width: /Monto/.test(title) || /Fecha/.test(title) ? 150 : 0,
            flex: /Monto/.test(title) || /Fecha/.test(title) ? 0 : 1,
        }]
    }, [])
};

export const retrieveData = async (credentials, setRows, getData, setData) => {
    if (setRows) setRows([]);
    const data = await getData(credentials)
    setData(data)
};


export const exportarCSV = (data, title) => {
    if (data.length === 0) return
    let CsvString = "";
    // Encabezado
    Object.keys(data[0]).forEach(key => CsvString += (key + ','))
    CsvString += "\r\n";
    data.forEach((RowItem) => {
        Object.keys(RowItem).forEach((key) => {
            CsvString += RowItem[key] + ',';
        });
        CsvString += "\r\n";
    });
    CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    let x = document.createElement("a");
    x.setAttribute("href", CsvString);
    x.setAttribute("download", `${title}.csv`);
    document.body.appendChild(x);
    x.click();
};